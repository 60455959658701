export const environment = {
  showDefaultLogin: false,
  production: true,
  baseUrl: 'https://apibeta.plataformaesa.com.br',
  baseUrlWordPress: 'https://blog.plataformaesa.com.br/wp-json/wp/v2',
  baseAwsVideo: 'https://plataforma-esa-videos-noprod.s3.sa-east-1.amazonaws.com/',
  mercadoPagoToken: '',
  recaptcha: {
    siteKey: "6LexkkggAAAAANuv-R_j33l4wFj1H4uf1ek9e039"
  }
};
